<template>
  <div class="app-container">
    <el-form>
      <el-form-item style="width: 400px">
        <el-upload
          class="upload-demo"
          drag
          action=""
          accept=".mlb"
          ref="upload"
          :auto-upload="false"
          :multiple="false"
          :limit="1"
          :on-change="handleChange"
          :on-remove="handleRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop *.mlb files here!</div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onConvertToJSON1"
          >Convert to JSON (No Space)</el-button
        >
        <el-button type="primary" @click="onConvertToJSON2"
          >Convert to JSON (Form)</el-button
        >
        <el-button type="primary" @click="onConvertToES6"
          >Convert to Script (ES6)</el-button
        >
      </el-form-item>

      <el-input
        type="textarea"
        :rows="20"
        placeholder="Parsing Data"
        v-model="textarea"
      >
      </el-input>
    </el-form>
  </div>
</template>


<script>
import { Message } from "element-ui";
import apiGeneral from "@/api/apiGeneral.js";

export default {
  name: "mlb_to_json",
  components: {},
  data() {
    return {
      uploadFileList: [],
      textarea: "",
      loading: false,
    };
  },
  created() {},

  methods: {
    //---------------------------------------------------------
    onConvertToJSON1() {
      if (this.uploadFileList.count === 0) {
        Message({
          message: "Please browse a bundle file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;
      let fileData = undefined;
      var reader = new FileReader();
      reader.readAsText(this.uploadFileList[0].raw);

      reader.onload = (event) => {
        fileData = event.target.result;
        this.loading = false;

        if (fileData === undefined) {
          Message({
            message: "Can't parsing upload file!",
            type: "error",
            duration: 5 * 1000,
          });
          return;
        }

        let convertedData = JSON.stringify(apiGeneral.convertMLBToJson(fileData));
        //this.textarea = JSON.stringify(convertedData, null, 0);

        this.textarea = convertedData;
      };
    },

    //---------------------------------------------------------
    onConvertToJSON2() {
      if (this.uploadFileList.count === 0) {
        Message({
          message: "Please browse a bundle file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;
      let fileData = undefined;
      var reader = new FileReader();
      reader.readAsText(this.uploadFileList[0].raw);

      reader.onload = (event) => {
        fileData = event.target.result;
        this.loading = false;

        if (fileData === undefined) {
          Message({
            message: "Can't parsing upload file!",
            type: "error",
            duration: 5 * 1000,
          });
          return;
        }

        let convertedData = JSON.stringify(apiGeneral.convertMLBToJson(fileData), null, 4);
        convertedData = convertedData.replaceAll("    ","\t");
        this.textarea = convertedData;
      };
    },

    //---------------------------------------------------------
    onConvertToES6() {
      if (this.uploadFileList.count === 0) {
        Message({
          message: "Please browse a bundle file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;
      let fileData = undefined;
      var reader = new FileReader();
      reader.readAsText(this.uploadFileList[0].raw);

      reader.onload = (event) => {
        fileData = event.target.result;
        this.loading = false;

        if (fileData === undefined) {
          Message({
            message: "Can't parsing upload file!",
            type: "error",
            duration: 5 * 1000,
          });
          return;
        }

        let convertedData = apiGeneral.convertMLBToES6(fileData);
        this.textarea = convertedData;
      };
    },

    //------------------------------------------------------------------------------------
    onSave() {},

    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleRemove(file, fileList) {
      this.uploadFileList = fileList;
    },

    //------------------------------------------------------------------------------------
    handleChange(file, fileList) {
      this.uploadFileList = fileList;
    },
  },
};
</script>